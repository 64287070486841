.tds-grid-fixed,
.tds-grid-fluid {
  outline: 1px solid black;
  /*background: grey;*/
  }
.tds-grid-item {
  /*height: 200px;
  background: #ef9191;
  border: 1px solid #ccc;*/
  text-align: left;
}
.tds-grid-fixed,
.tds-grid-fluid {
  display: grid;
  width: calc(100% - 32px);
  padding: 0 16px;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
}
ul.no-bullets {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}
.speedlimit {
  display: block;
  height: 60px;
  width: 60px;
  line-height: 60px;

  -moz-border-radius: 30px; /* or 50% */
  border-radius: 30px; /* or 50% */
  border:6px solid red;
  background-color: #FFEA00;
  border-color: red;
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 2em;
}

.weightlimit {
  display: block;
  height: 60px;
  width: 60px;
  line-height: 60px;

  -moz-border-radius: 30px; /* or 50% */
  border-radius: 30px; /* or 50% */
  border:6px solid red;
  background-color: #FFEA00;
  border-color: red;
  color: black;
  text-align: center;
  font-weight: bold;
  font-size: 1.5em;
}
.toohigh {
  color: red;
}

.driveradvice {
  font-weight: bold;
  font-size: 2em;

}